import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  regiment: string;
  menuItems = [];

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(private eventService: EventService, private router: Router, public translate: TranslateService, private http: HttpClient) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    this.regiment = localStorage.getItem('regiment_nom');

    this.initialize();
    this._scrollElement();

  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }

  }

  /**
   * Initialize
   */
  initialize(): void {

    let MENU_CHEF_GROUP: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 1,
            label: 'المسجلين بقاعدة البيانات',
            link: '/liste-all-adherants',
            parentId: 2
          },
          {
            id: 2,
            label: 'ملف فوج ' + localStorage.getItem('regiment_nom'),
            link: '/document-groupe/' + localStorage.getItem("regiment_id"),
            parentId: 2
          },






        ]
      },


      {
        id: 6,
        label: ' الأفراد ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 4,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 4,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 4
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
            ]
          },

          {
            id: 5,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 5,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 5
              },
            ]
          },
        ]
      },
      {
        id: 7,
        label: ' الهياكل ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '  تسجيل الهياكل ',
            link: '/reinscrire-regiment/' + localStorage.getItem("regiment_id"),
            icon: 'bx bxs-collection',
            parentId: 1,
          },
          {
            id: 2,
            label: 'الوحدات',
            icon: 'bx bx-stats',
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-unicites-non-valides',
                parentId: 2
              },
              {
                id: 2,
                label: 'قائمة الوحدات',
                parentId: 2,
                link: '/list-unicites-valides',
              }
            ]
          },
        ]
      },
      {
        id: 3,
        label: 'الإحصائيات',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '2022-2023',
            parentId: 3,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              }
            ]
          },
          {
            id: 2,
            label: '2022-2021',
            parentId: 3,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              }
            ]
          }
        ]
      }
    ];

    let MENU_CHEF_REGIONAL: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 1,
            label: 'المسجلين بقاعدة البيانات',
            link: '/liste-all-adherants',
            parentId: 2
          },
          /*{
            id: 3,
            label: 'ملف جهة ' +localStorage.getItem("region_nom"),
            parentId: 2,
            subItems: [
              {
                id: 1,
                label: 'ملف الجهة',
                link: '/document-region',
                parentId: 3
              },
              {
                id: 2,
                label: 'قائمة الأفواج',
                link: '/list-groupe-par-region',
                parentId: 3
              }
            ]
          },*/
          {
            id: 3,
            label: 'ملف جهة ' + localStorage.getItem("region_nom"),
            parentId: 2,
            link: '/document-region/' + localStorage.getItem("region_id"),
          },
        
          /* {
            id: 4,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 4,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 4
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
            /*]
          },*/

         

        ]
      },
      {
        id: 3,
        label: 'الإعدادات',
        icon: 'bx bxs-brightness',
        subItems: [

          {
            id: 4,
            label: 'مستخدمي التطبيقة',
            link: '/liste-chefs',
            parentId: 3,
          },

        ]
      },
    
     
      {
        id: 9,
        label: ' الأفراد ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            parentId: 9,
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 1,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 1
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
            ]
          },
          {
            id: 2,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 2,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 2
              },
            ]
          }
        ]
      },
      {
        id: 10,
        label: ' الهياكل ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '  تسجيل الهياكل ',
            link: '/list-regiments-non-valides',
            parentId: 10,
            icon: 'bx bxs-collection',
          },
          {
            id: 2,
            label: 'الوحدات',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-unicites-non-valides',
                parentId: 2
              },
              {
                id: 2,
                label: 'قائمة الوحدات',
                parentId: 2,
                link: '/list-unicites-valides',
              }
            ]
          },
          {
            id: 3,
            label: 'الأفواج',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-regiments-non-valides',
                parentId: 3
              },
              {
                id: 2,
                label: 'قائمة الأفواج',
                parentId: 3,
                link: '/list-regiments-valides',
              },
            ]
          },
        ]
      },
      
      {
        id: 3,
        label: 'الإحصائيات',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '2022-2023',
            parentId: 3,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              },
              {
                id: 3,
                label: 'الهياكل',
                parentId: 1
              }
            ]
          },
          {
            id: 2,
            label: '2022-2021',
            parentId: 3,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              },
              {
                id: 3,
                label: 'الهياكل',
                parentId: 1
              }
            ]
          }
        ]
      }
    ];


    let MENU_SUPER_ADMIN: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 1,
            label: 'القوائم الجهوية',
            link: '/list-region',
            parentId: 2
          },
          {
            id: 2,
            label: 'المسجلين بقاعدة البيانات',
            link: '/liste-all-adherants',
            parentId: 2
          },
        /*  {
            id: 4,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 4,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 4
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
           /* ]
          },

          {
            id: 5,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 5,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 5
              },
            ]
          }*/
        ]
      },
      {
        id: 3,
        label: 'الإعدادات',
        icon: 'bx bxs-brightness',
        subItems: [
          {
            id: 1,
            label: 'ملف الكشافة',
            link: '#',
            parentId: 3
          },
          {
            id: 2,
            label: 'الأقسام الفنية',
            link: '/liste-classes',
            parentId: 3
          },
          {
            id: 3,
            label: 'صفات الأعضاء',
            link: '/list-roles',
            parentId: 3
          },
          {
            id: 4,
            label: 'مستخدمي التطبيقة',
            link: '/liste-chefs',
            parentId: 3,
          },
          {
            id: 5,
            label: 'السنوات الكشفية',
            link: '/list-annees-scouts',
            parentId: 3,
          },
          /* {
             id : 6,
             label : 'الامتيازات',
             link: '/liste-privileges',
             parentId: 3,
           }*/
        ]
      },
      {
        id: 9,
        label: ' الأفراد ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            parentId: 9,
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 1,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 1
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
            ]
          },
          {
            id: 2,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 2,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 2
              },
            ]
          }
        ]
      },
      {
        id: 10,
        label: ' الهياكل ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '  تسجيل الهياكل ',
            link: '/list-regiments-non-valides',

            parentId: 10,
            icon: 'bx bxs-collection',
          },
          {
            id: 2,
            label: 'الوحدات',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-unicites-non-valides',
                parentId: 2
              },
              {
                id: 2,
                label: 'قائمة الوحدات',
                parentId: 2,
                link: '/list-unicites-valides',
              }
            ]
          },
          {
            id: 3,
            label: 'الأفواج',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-regiments-non-valides',
                parentId: 3
              },
              {
                id: 2,
                label: 'قائمة الأفواج',
                parentId: 3,
                link: '/list-regiments-valides',
              },
            ]
          },
        ]
      },
      
    
     /* {
        id: 6,
        label: 'الأفواج',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: ' طلبات الاشتراك',
            link: '/list-regiments-non-valides',
            parentId: 6
          },
          {
            id: 2,
            label: 'قائمة الأفواج',
            parentId: 6,
            link: '/list-regiments-valides',
          },
        ]
      },
      {
        id: 7,
        label: 'الوحدات',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: ' طلبات الاشتراك',
            link: '/list-unicites-non-valides',
            parentId: 7
          },
          {
            id: 2,
            label: 'قائمة الوحدات',
            parentId: 7,
            link: '/list-unicites-valides',
          }
        ]
      },*/
      /*{
        id: 6,
        label: 'قائمة الامتيازات',
        link: '/liste-privileges',
        icon: 'bx bx-shield-quarter',
      },*/
      {
        id: 5,
        label: 'طباعة الإنخراطات',
        link: '#',
        icon: 'bx bx-printer',
      },
     /* {
        id: 8,
        label: '  تسجيل الهياكل ',
        link: '/list-regiments-non-valides',
        icon: 'bx bxs-collection',
      },*/
      {
        id: 4,
        label: 'الإحصائيات',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '2022-2023',
            parentId: 4,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              }
            ]
          },
          {
            id: 2,
            label: '2022-2021',
            parentId: 4,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                parentId: 2
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 2
              }
            ]
          }
        ]
      },
      {
        id: 19,
        label: 'Migration temporaire',
        link: '/migration-temporaire',
        icon: 'bx bxs-collection',
      },
    ];
    let MENU_CHEF_NATIONAL: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 1,
            label: 'القوائم الجهوية',
            link: '/list-region',
            parentId: 2
          },
          {
            id: 2,
            label: 'المسجلين بقاعدة البيانات',
            link: '/liste-all-adherants',
            parentId: 2
          },
        /*  {
            id: 4,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 4,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 4
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
           /* ]
          },

          {
            id: 5,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 5,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 5
              },
            ]
          }*/
        ]
      },
      {
        id: 3,
        label: 'الإعدادات',
        icon: 'bx bxs-brightness',
        subItems: [
          {
            id: 1,
            label: 'ملف الكشافة',
            link: '#',
            parentId: 3
          },
          {
            id: 4,
            label: 'مستخدمي التطبيقة',
            link: '/liste-chefs',
            parentId: 3,
          },
         /* {
            id: 2,
            label: 'الأقسام الفنية',
            link: '/liste-classes',
            parentId: 3
          },
          {
            id: 3,
            label: 'صفات الأعضاء',
            link: '/list-roles',
            parentId: 3
          },
          {
            id: 4,
            label: 'مستخدمي التطبيقة',
            link: '/liste-chefs',
            parentId: 3,
          },
          {
            id: 5,
            label: 'السنوات الكشفية',
            link: '/list-annees-scouts',
            parentId: 3,
          },
          /* {
             id : 6,
             label : 'الامتيازات',
             link: '/liste-privileges',
             parentId: 3,
           }*/
        ]
      },
      {
        id: 9,
        label: ' الأفراد ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: ' الإشتراك ',
            link: '/list-inscription-by-groupe/nouvelle-inscription',
            parentId: 9,
            subItems: [
              {
                id: 1,
                label: 'طلبات إعادة الإشتراك',
                link: '/list-reinscription-by-groupe/demande-reinscription',
                parentId: 1,
              },
              {
                id: 2,
                label: 'طلبات الإشتراك (مشاركين جدد)',
                link: '/list-inscription-by-groupe/nouvelle-inscription',
                parentId: 1
              },
              /*{
                id: 3,
                label: 'قائمة المشاركين',
                link: 'liste-adherants',
                parentId: 4
              },*/
            ]
          },
          {
            id: 2,
            label: 'الاستخلاص',
            icon: 'bx bxs-brightness',
            subItems: [
              {
                id: 1,
                label: ' إنتظار تأكيد الاستخلاص',
                link: '/list-demande-preuve-paiement-inscription',
                parentId: 2,
              }, {
                id: 2,
                label: 'المسجلين بالكشافة',
                link: '/liste-paiement-valid',
                parentId: 2
              },
            ]
          }
        ]
      },
      {
        id: 10,
        label: ' الهياكل ',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '  تسجيل الهياكل ',
            link: '/list-regiments-non-valides',

            parentId: 10,
            icon: 'bx bxs-collection',
          },
          {
            id: 2,
            label: 'الوحدات',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-unicites-non-valides',
                parentId: 2
              },
              {
                id: 2,
                label: 'قائمة الوحدات',
                parentId: 2,
                link: '/list-unicites-valides',
              }
            ]
          },
          {
            id: 3,
            label: 'الأفواج',
            icon: 'bx bx-stats',
            parentId: 10,
            subItems: [
              {
                id: 1,
                label: ' طلبات الاشتراك',
                link: '/list-regiments-non-valides',
                parentId: 3
              },
              {
                id: 2,
                label: 'قائمة الأفواج',
                parentId: 3,
                link: '/list-regiments-valides',
              },
            ]
          },
        ]
      },
      {
        id: 5,
        label: 'طباعة الإنخراطات',
        link: '#',
        icon: 'bx bx-printer',
      },
     
      {
        id: 4,
        label: 'الإحصائيات',
        icon: 'bx bx-stats',
        subItems: [
          {
            id: 1,
            label: '2022-2023',
            parentId: 4,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                link: '/stats-inscription-groupe',
                parentId: 1
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 1
              }
            ]
          },
          {
            id: 2,
            label: '2022-2021',
            parentId: 4,
            subItems: [
              {
                id: 1,
                label: 'إحصاء المنخرطين',
                parentId: 2
              },
              {
                id: 2,
                label: 'القادة و المشاركون بكل قسم فني',
                parentId: 2
              }
            ]
          }
        ]
      },
    ];
    let MENU_USER: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 4,
            label: 'الملف الكشفي',
            link: '/profile-user/' + localStorage.getItem("id_user"),
            parentId: 2
          },
          /* {
             id: 1,
             label: 'الفوج ',
             link: '#',
             parentId: 2
           },
           {
             id: 2,
             label: 'الوحدة',
             link: '#',
             parentId: 2
           },*/

          {
            id: 3,
            label: 'متابعة التسجيل',
            link: '/demande-reinscription',
            parentId: 2
          },

        ]
      },





    ];

    let MENU_CHEF_EQUIPE: MenuItem[] = [
      {
        id: 1,
        label: 'الرئيسية',
        link: '/',
        icon: 'bx-home-circle',
      },
      {
        id: 2,
        label: 'الضبط العام',
        icon: 'bx bx-user-circle',
        subItems: [
          {
            id: 1,
            label: 'الفوج ',
            link: '/list-region',
            parentId: 2
          },
          {
            id: 2,
            label: '  الوحدة',
            link: '#',
            parentId: 2
          },

          {
            id: 3,
            label: 'مطلب إعادة التسجيل',
            link: '/demande-reinscription',
            parentId: 2
          },
          {
            id: 4,
            labes: 'طلبات التسجيل',
            link: '/demande-reinscription',
            parentId: 2
          }

        ]
      },





    ];

    let roles = localStorage.getItem('roles_user');
    if (roles == "ROLE_ADMIN_NATIONAL") {
      this.menuItems = MENU_CHEF_NATIONAL;
    }
    else if (roles == "ROLE_ADMIN_REGIONAL") {
      this.menuItems = MENU_CHEF_REGIONAL;
    }
    else if (roles == "ROLE_ADMIN_GROUPE") {
      this.menuItems = MENU_CHEF_GROUP;
    }
    else if (roles == "ROLE_ADMIN_EQUIPE") {
      this.menuItems = MENU_CHEF_EQUIPE;
    }
    else if (roles == "ROLE_USER") {
      this.menuItems = MENU_USER;
    }
    else if (roles == "ROLE_SUPER_ADMIN") {
      this.menuItems = MENU_SUPER_ADMIN
    }

    //this.menuItems = MENU_CHEF_GROUP;
    //this.menuItems = MENU_CHEF_NATIONAL;
    //this.menuItems = MENU_CHEF_REGIONAL;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
