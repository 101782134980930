import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsersAbonnes } from 'src/app/Models/UsersAbonnes.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: UsersAbonnes | null = null;
  urlApiGestion: string = environment.urlApiGestion + "gestion/";

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': environment.API_Key_MS_Gestion,
    // "Authorization" : 'Bearer '+ localStorage.getItem('access_token')
  }
  );

  options = { headers: this.headers };

  constructor(private httpClient: HttpClient) { }

  /**
       * Returns the current user
       */
  public currentUser(): UsersAbonnes | null {
    return JSON.parse(localStorage.getItem('currentUser')!);
  }

  login(email: string, password: string): any {
    return this.httpClient.post<any>(this.urlApiGestion + "login", { email, password }, this.options);
  }
  loginNumInsc(numInsc: string, password: string): any {
    return this.httpClient.post<any>(this.urlApiGestion + "login-num-insc", { numInsc, password }, this.options);
  }
  uploadImage(obj: any) {
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'x-api-key': environment.API_Key_MS_Gestion,
      "Authorization": 'Bearer ' + localStorage.getItem('access_token')
    });
    let options = { headers: headers };
    const formData = this.toFormData(obj);
    return this.httpClient.post<any>(this.urlApiGestion + "upload ", formData, options);

  }
  registerNew(usersAbonnes: any): any {


    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      'x-api-key': environment.API_Key_MS_Gestion,
      //"Authorization" : 'Bearer '+ localStorage.getItem('access_token')
    });

    let options = { headers: this.headers };

    return this.httpClient.post<any>(this.urlApiGestion + "register/new", usersAbonnes, options);
  }
  registerOld(id: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "register/old/" + id, {}, this.options);
  }

  /**
* Logout the user
*/
  logout(): void {
    // remove user from session storage to log user out
    sessionStorage.clear();
    let annee = localStorage.getItem("saison_courant_nom")
    let id = localStorage.getItem("saison_courant")
    localStorage.clear();
    localStorage.setItem("saison_courant", id);
    localStorage.setItem("saison_courant_nom", annee);

    this.user = null;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }
  getAll(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/all", this.options);

  }
  getUsersByRegionRegiment(region: number, regiment: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/all", { region: { id: region }, regiment: { id: regiment } }, this.options);
  }
  getUsersByRegion(region: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/all-regional", { region: { id: region } }, this.options);
  }
  getUsersNational(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/all-national", this.options);
  }
  getAllPaginator(pageNumber: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/paginator", { pageNumber }, this.options);

  }
  getOne(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/one/" + id, this.options);

  }
  verifInscription(id: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/verif-adherent-inscri", {
      "userId": id,
      "saison": Number(localStorage.getItem('saison_courant'))
    }, this.options);


  }
  getAllPaiementValid(regiment: number, saison: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "affectation/valid?regiment=" + regiment + "&anneeScouts=" + saison, this.options);
  }

  getAllReinscriptionNonValid(regiment: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/anciens/nonvalide/" + regiment, this.options);
  }


  //APIs pour les chefs qui n'ont pas une regiment

  getAllReinscriptionNonValidChefs(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/anciens/chefs/nonvalide",  this.options);
  }

  getAllInscriptionNonValidChefs(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux/chefs/nonvalide", this.options);

  }
  getListeAdherantsPaimentNonValidChefs(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/chefs/nouveaux-accepte-paiement", this.options)
  }
  getListeAdherantsPaimentValidChefs(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/chefs/valide-paiement-user", this.options)
  }





  getAllInscriptionNonValid(regiment: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux/nonvalide/" + regiment, this.options);

  }

  Accepter(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux/user-abonnes-accepte/" + id, this.options)
  }
  AccepterAnciens(id: number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/anciens/user-abonnes-accepte/" + id, { saison: Number(localStorage.getItem("saison_courant")) }, this.options)
  }

  AcceptList(list: UsersAbonnes[]): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/nouveaux/user-abonnes-accepte-plusieurs", list, this.options)
  }

  Refuser(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux/user-abonnes-refuse/" + id, this.options)

  }
  //recuperer la liste des abonnés dont l isscription ou la reinscription est valide
  getListeAdherants(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux-accepte/valide/" + id, this.options)
  }

  getListeALLAdherantsFiltres(obj: any): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/list-adherent-regiment", obj, this.options)
  }

  getListeAdherantsPaimentNonValid(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/nouveaux-accepte-paiement/" + id, this.options)
  }

  getListeAdherantsPaimentValid(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/valide-paiement-user/" + id, this.options)
  }
  updatePrevilege(user: UsersAbonnes): any {
    return this.httpClient.post<any>(this.urlApiGestion + "affectation/affect-user/" + user.id, user, this.options)

  }
  Modifier(user: UsersAbonnes): any {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/modif-user/" + user.id, user, this.options)

  }
  firstEdit(user: UsersAbonnes): any {
    return this.httpClient.put<any>(this.urlApiGestion + "/abonnes/first-user-edit/" + user.id, user, this.options)

  }
  ModifierDenneesCnx(data: any, id: number): any {
    return this.httpClient.put<any>(this.urlApiGestion + "abonnes/edit/pwd-email-user/" + id, data, this.options)

  }
  getListChefsRegiment(regiment: number, annees: number) {
    return this.httpClient.post<any>(this.urlApiGestion + "abonnes/all-users/" + regiment, { annees }, this.options)
  }

  reinscrire(id: number, obj: any) {
    return this.httpClient.post<any>(this.urlApiGestion + "register/old/" + id, obj, this.options)
  }
  getListChefsNational() {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/liste-national", this.options)
  }
  getListChefsGroupe() {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/liste-groupe", this.options)
  }

  getListChefsGroupeRegional(id:number) {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/liste-groupe-region/"+id, this.options)
  }
  getListChefsRegional() {
    return this.httpClient.get<any>(this.urlApiGestion + "abonnes/liste-regional", this.options)
  }

  changeRoles(id: number, roles: any): any {
    return this.httpClient.post<any>(this.urlApiGestion + "/role/change-roles/" + id, { roles }, this.options)
  }
  getAdherantsEquipes(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "equipe/adherent/" + id, this.options)

  }
  getListeAllAdherants(): any {
    return this.httpClient.get<any>(this.urlApiGestion + "/adherent/", this.options)
  }

  getListByEquipe(id: number,annees : number): any {
    return this.httpClient.post<any>(this.urlApiGestion + "/abonnes/all-users-inscrits/" + id,{annees}, this.options)
  }


  getHistorique(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "affectation/user/" + id, this.options)

  }


  changeActive(id: number, active: boolean): any {
    return this.httpClient.post<any>(this.urlApiGestion + "affectation/active-user", { idUser: id, active: active }, this.options)

  }

  public toFormData<T>(formValue: any) {
    const formData = new FormData();
    console.log(formValue);


    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (key == "regiment" || key == "classe" || key == "listeEquipes") {
        formData.append(key, JSON.stringify(value));
      }
      else {
        formData.append(key, value);
      }
      formData.append(key, value);
    }

    return formData;
  }



}
