import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var require: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();
  version: string = environment.APP_VERSION;
  
  constructor() { }
annee:string='';
  ngOnInit() {
    this.annee=localStorage.getItem("saison_courant_nom");

  }

}
