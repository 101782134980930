export const environment = {
  production: false,
  urlApiGestion : 'https://d-apigw.scouts-tn.org/apigw/v2/api/',
  urlApiAuthini : 'tbd',

  API_Key_MS_Gestion : '38a670e4-def8-4216-9e10-5458ff4dfb27',
  API_Key_MS_Authini : 'tbd',

  APP_NAME : "MYSCOUT-FE",
  APP_VERSION :"0.19.8",
  frontend_URL : "https://d-my.scouts-tn.org/"

};
