import { Component , OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnneesService } from './services/Annees/annees.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(public translate: TranslateService,private anneservice : AnneesService) { }

  ngOnInit() {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.anneservice.getSaisonCourant().subscribe((data:any)=>{
      localStorage.setItem("saison_courant",data.id);
      localStorage.setItem("saison_courant_nom",data.nom);

    })
  }

  getcurrentlang(){
    return this.translate.currentLang;
  }
}
