import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Annees } from 'src/app/Models/Annees.model';
import { Region } from 'src/app/Models/Region.model';
import { UsersAbonnes } from 'src/app/Models/UsersAbonnes.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnneesService {

  user: UsersAbonnes | null = null;
  urlApiGestion: string = environment.urlApiGestion+"gestion/";

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': environment.API_Key_MS_Gestion,
    "Authorization": 'Bearer ' + localStorage.getItem('access_token')
  });

  options = { headers: this.headers };

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<Annees[]> {
    return this.httpClient.get<any>(this.urlApiGestion + "annees/", this.options);
  }
  getOne(id: number): any {
    return this.httpClient.get<any>(this.urlApiGestion + "annees/" + id, this.options);
  }

  getSaisonCourant(): any {
    //console.log(localStorage.getItem('access_token'));
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.API_Key_MS_Gestion,
      "Authorization": 'Bearer ' + localStorage.getItem('access_token')
    });
    return this.httpClient.get<any>(this.urlApiGestion + "annees/courant",  { headers: headers });
  }

  Ajouter(saison: Annees): any {
    return this.httpClient.post<any>(this.urlApiGestion + "annees/new",  saison , this.options);
  }

  Modifier(id: number, saison: Annees): any {
    return this.httpClient.put<any>(this.urlApiGestion + "annees/edit/" + id,  saison , this.options);
  }

  Supprimer(id: number): any {
    return this.httpClient.delete<any>(this.urlApiGestion + "annees/delete/" + id , this.options);
  }
}
